import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm';
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import VGeosearch from 'vue2-leaflet-geosearch';


Vue.use( TurbolinksAdapter );

document.addEventListener( 'turbolinks:load', () => {
    const el = document.getElementById( 'estate-map' );
    if( !el ) return false;

    const mapa = new Vue( {
        el,
        components: {
            LMap,
            LTileLayer,
            LMarker,
            LIcon,
            VGeosearch
        },
        mounted() {
            const { lat, lng } = document.getElementById( 'estate-map' ).dataset;
            this.marker.lat = lat;
            this.marker.lng = lng;
        },
        methods: {
            reloadMapSize() {
                setTimeout( this.invalidateSizeMap, 500 );
            },
            invalidateSizeMap() {
                this.$nextTick( () => {
                    this.$refs.the_map.mapObject.invalidateSize();
                } );
            },
            removeMarker() {
                this.marker = { lat: '', lng: '' };
            },
            addMarker( event ) {
                this.marker = event.latlng;
            }
        },
        data() {
            return {
                zoom: 11,
                center: [6.253872, -75.563965],
                marker: {
                    lat: '',
                    lng: ''
                },
                geosearchOptions: {
                    provider: new OpenStreetMapProvider()
                }
            };
        }
    } );
} );
